import {CalculationFormModel} from '@app/calculate/calculate/calculation-form-model';
import {Injectable} from '@angular/core';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {StoreService} from '@app/shared/services/store/store.service';
import {Observable, of} from 'rxjs';
import {InsuranceType, VehicleModel} from '@app/calculate/vehicle/vehicle.model';
import {VehicleAdditionalInfoModel} from '@app/calculate/vehicle-additional-info/vehicle-additional-info.model';
import {VehicleOwnerModel} from '@app/calculate/vehicle-owner/vehicle-owner.model';
import {VehicleOwnerDetailsModel} from '@app/calculate/vehicle-owner-details/vehicle-owner-details.model';
import {InsuranceStartDateModel} from '@app/calculate/insurance-start-date/insurance-start-date.model';
import {CalculationDictionaryModel} from '@app/calculate/calculate/calculation-dictionary.model';
import {CoverModel, InsuranceVariantModel} from '@app/calculate/calculate/insurance-variant.model';
import {LandingPageModel} from '@app/landing-page/landing-page.model';
import {InsuranceTotalPriceModel} from '@app/shared/models/insurance-total-price/insurance-total-price.model';
import {VehicleRegistrationDataModel} from '@app/offer/vehicle-registration-data/vehicle-registration-data.model';
import {
  VehicleOwnerAdditionalAddressModel
} from '@app/offer/vehicle-owner-additional-address/vehicle-owner-additional-address.model';
import {ContactAndConsentModel} from '@app/offer/contact-and-consent/contact-and-consent.model';
import {CalculationResponseModel} from '@app/calculate/calculate/calculation-response.model';
import * as dayjs from 'dayjs';
import {
  VehicleOwnerAdditionalDetailsModel
} from "@app/offer/vehicle-owner-additional-details/vehicle-owner-additional-details.model";
import {PersonalDataStoreModel, PersonalDataType} from "@app/shared/services/personal-data/personal-data-store.model";
import {UrlParamsStoreService} from "@app/shared/interceptors/partner/url-params-store.service";
import {UrlParamsModel} from "@app/shared/models/url-parameters/url-params.model";
import {CallbackModel} from "@app/shared/components/callback/callback.model";
import {GeneratorService} from "@app/shared/services/generator/generator.service";
import {CepikResponse} from "@app/shared/models/cepik/cepik-response.model";
import {GetPartnerConsentResult} from "@app/shared/services/consent/consent-list.model";
import {OfferRecalculatedResponse} from "@app/shared/services/omni/omni.model";
import {ModifyCalculationResponseModel} from "@app/calculate/calculate/modify-calculation-response.model";

export enum CalculationStoreKey {
  CALCULATION_ID = 'calculation-id',
  CALCULATION_DICTIONARY = 'calculation-dictionary',
  CALCULATION_RESPONSE = 'calculation_response',
  ADDITIONAL_INSURANCE_VARIANT = 'additional-insurance-variant',
  POLICY = 'policy',
  ADDITIONAL_COVERS = 'additional-covers',
  POLICY_SOLD = 'policy-sold',
  SAVED_CALCULATION_FORM = 'saved-calculation-form',
  SAVED_CALLBACK_FORM = 'saved-callback-form',
  OFFER_NUMBER = 'offer-number',
  OFFER_RENEWAL = 'offer-renewal',
  AUTH_INIT_REQUEST_COUNT = 'auth-init-request-count',
  USER_SEE_SMS_AUTH_ERROR = 'user-see-sms-auth-error',
  FLOW_ID = 'flow-id',
  PROPOSAL_DATA = 'proposal-data',
  OMNI_OFFER_DATA = 'omni-offer-data',
  POLICY_OFFER_TOKEN = 'policy-offer-token',
  SELECTED_COVERS = 'selected-covers',
  FIRST_COVERS_ON_OMNI_OFFER = 'first-covers-on-omni-offer',
  LANDING_PAGE_CORRECT_DATA_COUNT = 'landing-page-correct-data-count',
  SELL_DOCUMENTS_INSURER_EMAIL = 'sell-documents-insurer-email',
  SELL_DOCUMENTS_OWNER_EMAIL = 'sell-documents-owner-email'
}

export enum StoreKeyNotClear {
  CONSENTS = 'consents',
}

@Injectable()
export class CalculationStoreService extends StoreService {
  private lastModificationDate = dayjs();

  constructor(private urlParamsStoreService: UrlParamsStoreService,
              private generatorService: GeneratorService) {
    super();
  }

  protected storeStep<T>(step: Step | OfferStep, item: T): Observable<void> {
    this.lastModificationDate = dayjs();
    return super.storeStep(step, item);
  }

  hasStoredStep(step: Step | OfferStep): boolean {
    return this.getStep(step) !== null;
  }

  hasCompletedFirstStep(): boolean {
    return this.hasStoredStep(Step.LANDING_PAGE);
  }

  getLastModificationDate() {
    return this.lastModificationDate.clone();
  }

  storeLandingPage(landingPage: LandingPageModel): Observable<void> {
    return this.storeStep(Step.LANDING_PAGE, landingPage);
  }

  getLandingPage(): LandingPageModel {
    return this.getStep(Step.LANDING_PAGE);
  }

  storeVehicle(vehicle: VehicleModel): Observable<void> {
    return this.storeStep(Step.VEHICLE, vehicle);
  }

  getVehicle(): VehicleModel {
    return this.getStep(Step.VEHICLE);
  }

  storeVehicleAdditionalInfo(vehicleAdditionalInfo: VehicleAdditionalInfoModel): Observable<void> {
    return this.storeStep(Step.VEHICLE_ADDITIONAL_INFO, vehicleAdditionalInfo);
  }

  getVehicleAdditionalInfo(): VehicleAdditionalInfoModel {
    return this.getStep(Step.VEHICLE_ADDITIONAL_INFO);
  }

  storeVehicleOwner(vehicleOwner: VehicleOwnerModel): Observable<void> {
    return this.storeStep(Step.VEHICLE_OWNER, vehicleOwner);
  }

  getVehicleOwner(): VehicleOwnerModel {
    return this.getStep(Step.VEHICLE_OWNER);
  }

  storeMainVehicleUserDetails(mainVehicleUserDetails: PersonalDataStoreModel): Observable<void> {
    const {mainUser} = this.getVehicleOwner();

    switch (mainUser) {
      case PersonalDataType.OWNER:
        return of(this.storeItem(PersonalDataType.OWNER, mainVehicleUserDetails));
      case PersonalDataType.FIRST_CO_OWNER:
        return of(this.storeItem(PersonalDataType.FIRST_CO_OWNER, mainVehicleUserDetails));
      case PersonalDataType.SECOND_CO_OWNER:
        return of(this.storeItem(PersonalDataType.SECOND_CO_OWNER, mainVehicleUserDetails));
    }
  }

  getMainVehicleUserDetails(): PersonalDataStoreModel {
    try {
      const {mainUser} = this.getVehicleOwner();

      switch (mainUser) {
        case PersonalDataType.OWNER:
          return this.getItem(PersonalDataType.OWNER);
        case PersonalDataType.FIRST_CO_OWNER:
          return this.getItem(PersonalDataType.FIRST_CO_OWNER);
        case PersonalDataType.SECOND_CO_OWNER:
          return this.getItem(PersonalDataType.SECOND_CO_OWNER);
      }
    } catch (e) {
      return null;
    }
  }

  storeVehicleOwnerDetails(vehicleOwnerDetails: VehicleOwnerDetailsModel): Observable<void> {
    return this.storeStep(Step.VEHICLE_OWNER_DETAILS, vehicleOwnerDetails);
  }

  getVehicleOwnerDetails(): VehicleOwnerDetailsModel {
    return this.getStep(Step.VEHICLE_OWNER_DETAILS);
  }

  getVehicleOwnerAdditionalDetails(): VehicleOwnerAdditionalDetailsModel {
    return this.getStep(OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS);
  }

  storeVehicleOwnerAdditionalDetails(vehicleOwnerAdditionalDetails: VehicleOwnerAdditionalDetailsModel): Observable<void> {
    return this.storeStep(OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS, vehicleOwnerAdditionalDetails);
  }

  getVehicleFirstCoOwnerAdditionalDetails(): VehicleOwnerAdditionalDetailsModel {
    return this.getStep(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS);
  }

  storeVehicleFirstCoOwnerAdditionalDetails(vehicleFirstCoOwnerAdditionalDetails: VehicleOwnerAdditionalDetailsModel): Observable<void> {
    return this.storeStep(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS, vehicleFirstCoOwnerAdditionalDetails);
  }

  getVehicleSecondCoOwnerAdditionalDetails(): VehicleOwnerAdditionalDetailsModel {
    return this.getStep(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS);
  }

  storeVehicleSecondCoOwnerAdditionalDetails(vehicleSecondCoOwnerAdditionalDetails: VehicleOwnerAdditionalDetailsModel): Observable<void> {
    return this.storeStep(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS, vehicleSecondCoOwnerAdditionalDetails);
  }

  getPersonalData(type: PersonalDataType): PersonalDataStoreModel {
    return this.getItem(type);
  }

  getPersonalDataByStep(step: Step | OfferStep): PersonalDataStoreModel {
    switch (step) {
      case Step.VEHICLE_OWNER_DETAILS:
        return this.getItem(PersonalDataType.OWNER);
      case Step.VEHICLE_FIRST_CO_OWNER_DETAILS:
        return this.getItem(PersonalDataType.FIRST_CO_OWNER);
      case Step.VEHICLE_SECOND_CO_OWNER_DETAILS:
        return this.getItem(PersonalDataType.SECOND_CO_OWNER);
      case OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS:
        return this.getItem(PersonalDataType.OWNER);
      case OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS:
        return this.getItem(PersonalDataType.FIRST_CO_OWNER);
      case OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS:
        return this.getItem(PersonalDataType.SECOND_CO_OWNER);
    }
  }

  storeAuthInitRequestCount(count: number) {
    return this.storeItem(CalculationStoreKey.AUTH_INIT_REQUEST_COUNT, count);
  }

  getAuthInitRequestCount(): number {
    const authInitRequestCount = this.getItem(CalculationStoreKey.AUTH_INIT_REQUEST_COUNT);

    return authInitRequestCount ? parseInt(authInitRequestCount) : 0;
  }

  storeUserSeeSmsAuthErrorCount(count: number) {
    return this.storeItem(CalculationStoreKey.USER_SEE_SMS_AUTH_ERROR, count);
  }

  getUserSeeSmsAuthErrorCount(): number {
    const userSeeError = this.getItem(CalculationStoreKey.USER_SEE_SMS_AUTH_ERROR);

    return userSeeError ? parseInt(userSeeError) : 0;
  }

  storeVehicleFirstCoOwnerDetails(vehicleFirstCoOwnerDetails: VehicleOwnerDetailsModel): Observable<void> {
    return this.storeStep(Step.VEHICLE_FIRST_CO_OWNER_DETAILS, vehicleFirstCoOwnerDetails);
  }

  getVehicleFirstCoOwnerDetails(): VehicleOwnerDetailsModel {
    return this.getStep(Step.VEHICLE_FIRST_CO_OWNER_DETAILS);
  }

  storeFirstCoversOnOmniOffer(covers: CoverModel[]) {
    return this.storeItem(CalculationStoreKey.FIRST_COVERS_ON_OMNI_OFFER, covers);
  }

  getFirstCoversOnOmniOffer(): CoverModel[] {
    return this.getItem(CalculationStoreKey.FIRST_COVERS_ON_OMNI_OFFER);
  }

  storeVehicleSecondCoOwnerDetails(vehicleSecondCoOwnerDetails: VehicleOwnerDetailsModel): Observable<void> {
    return this.storeStep(Step.VEHICLE_SECOND_CO_OWNER_DETAILS, vehicleSecondCoOwnerDetails);
  }

  getVehicleSecondCoOwnerDetails(): VehicleOwnerDetailsModel {
    return this.getStep(Step.VEHICLE_SECOND_CO_OWNER_DETAILS);
  }

  storeVehicleOwnerDetailsByStep(vehicleOwnerDetails: VehicleOwnerDetailsModel, step: Step): Observable<void> {
    return this.storeStep(step, vehicleOwnerDetails);
  }

  storeInsuranceStartDate(insuranceStartDate: InsuranceStartDateModel): Observable<void> {
    return this.storeStep(Step.INSURANCE_START_DATE, insuranceStartDate);
  }

  getInsuranceStartDate(): InsuranceStartDateModel {
    return this.getStep(Step.INSURANCE_START_DATE);
  }

  storeInsuranceVariant(insuranceVariantModel: InsuranceVariantModel): Observable<void> {
    return this.storeStep(OfferStep.INSURANCE_VARIANT, insuranceVariantModel);
  }

  getInsuranceVariant(): InsuranceVariantModel {
    return this.getStep(OfferStep.INSURANCE_VARIANT);
  }

  storeCalculationId(calculationId: string) {
    this.storeItem(CalculationStoreKey.CALCULATION_ID, calculationId);
  }

  getCalculationId(): string {
    const storeCalculationId = this.getItem(CalculationStoreKey.CALCULATION_ID);
    let generateCalculationId: string;

    if (!storeCalculationId) {
      generateCalculationId = this.generatorService.generateCalculationId();
      this.storeCalculationId(generateCalculationId);
      return generateCalculationId;
    }

    return storeCalculationId;
  }

  storePolicyOfferToken(token: string) {
    this.storeItem(CalculationStoreKey.POLICY_OFFER_TOKEN, token);
  }

  getPolicyOfferToken(): string {
    return this.getItem(CalculationStoreKey.POLICY_OFFER_TOKEN);
  }

  storeSellDocumentsOwnerEmail(email: string) {
    this.storeItem(CalculationStoreKey.SELL_DOCUMENTS_OWNER_EMAIL, email);
  }

  getSellDocumentsOwnerEmail(): string {
    return this.getItem(CalculationStoreKey.SELL_DOCUMENTS_OWNER_EMAIL);
  }

  storeSellDocumentsInsurerEmail(email: string) {
    this.storeItem(CalculationStoreKey.SELL_DOCUMENTS_INSURER_EMAIL, email);
  }

  getSellDocumentsInsurerEmail(): string {
    return this.getItem(CalculationStoreKey.SELL_DOCUMENTS_INSURER_EMAIL);
  }

  storeFlowId(flowId: string) {
    this.storeItem(CalculationStoreKey.FLOW_ID, flowId);
  }

  getFlowId(): string {
    return this.getItem(CalculationStoreKey.FLOW_ID);
  }

  storeCalculationDictionary(calculationDictionary: CalculationDictionaryModel) {
    this.storeItem(CalculationStoreKey.CALCULATION_DICTIONARY, calculationDictionary);
  }

  getCalculationDictionary(): CalculationDictionaryModel {
    return this.getItem(CalculationStoreKey.CALCULATION_DICTIONARY);
  }

  storeInsuranceVariants(insuranceVariants: InsuranceVariantModel[]) {
    const calculationResponse = this.getCalculationResponse();
          calculationResponse.variants = insuranceVariants;

    this.storeItem(CalculationStoreKey.CALCULATION_RESPONSE, calculationResponse);
  }

  storeCalculationResponse(response: CalculationResponseModel) {
    this.storeItem(CalculationStoreKey.CALCULATION_RESPONSE, response);
  }

  storeAdditionalInsuranceVariant(insuranceVariant: InsuranceVariantModel) {
    this.storeItem(CalculationStoreKey.ADDITIONAL_INSURANCE_VARIANT, insuranceVariant);
  }

  getAdditionalInsuranceVariant(): InsuranceVariantModel {
    return this.getItem(CalculationStoreKey.ADDITIONAL_INSURANCE_VARIANT);
  }

  getCalculationResponse(): CalculationResponseModel {
    return this.getItem(CalculationStoreKey.CALCULATION_RESPONSE);
  }

  storePolicySold() {
    this.storeItem(CalculationStoreKey.POLICY_SOLD, true);
  }

  isPolicySold(): boolean {
    return this.getItem(CalculationStoreKey.POLICY_SOLD) !== null;
  }

  storeConsents(consents: GetPartnerConsentResult) {
    this.storeItem(StoreKeyNotClear.CONSENTS, consents);
  }

  getConsents(): GetPartnerConsentResult {
    return this.getItem(StoreKeyNotClear.CONSENTS);
  }

  storeAdditionalCovers(covers: CoverModel[]) {
    this.storeItem(CalculationStoreKey.ADDITIONAL_COVERS, covers);
  }

  getSavedCalculationForm(): CalculationFormModel {
    return this.getItem(CalculationStoreKey.SAVED_CALCULATION_FORM);
  }

  storeSavedCalculationForm(calculationForm: CalculationFormModel) {
    this.storeItem(CalculationStoreKey.SAVED_CALCULATION_FORM, calculationForm);
  }

  getOfferNumber(): string {
    return this.getItem(CalculationStoreKey.OFFER_NUMBER);
  }

  storeOfferNumber(offerNumber: string) {
    if (!!offerNumber) {
      this.storeItem(CalculationStoreKey.OFFER_NUMBER, offerNumber);
    }
  }

  getOfferRenewal(): boolean {
    return this.getItem(CalculationStoreKey.OFFER_RENEWAL);
  }

  storeOfferRenewal(offerRenewal: boolean) {
    this.storeItem(CalculationStoreKey.OFFER_RENEWAL, offerRenewal);
  }

  getAdditionalCovers() {
    return this.getItem(CalculationStoreKey.ADDITIONAL_COVERS);
  }

  storeSelectedAdditionalCovers(selectedCovers: CoverModel[]): Observable<void> {
    return this.storeStep(OfferStep.INSURANCE_ADDITIONAL_OPTION, selectedCovers);
  }

  getAdditionalSelectedCovers(): CoverModel[] {
    return this.getStep(OfferStep.INSURANCE_ADDITIONAL_OPTION);
  }

  storeSelectedCovers(selectedCovers: CoverModel[]) {
    return this.storeItem(CalculationStoreKey.SELECTED_COVERS, selectedCovers);
  }

  getSelectedCovers(): CoverModel[] {
    return this.getItem(CalculationStoreKey.SELECTED_COVERS);
  }

  storeInsuranceTotalPrice(insuranceTotlPrice: InsuranceTotalPriceModel): Observable<void> {
    return this.storeStep(OfferStep.INSURANCE_TOTAL_PRICE, insuranceTotlPrice);
  }

  getInsuranceTotalPrice(): InsuranceTotalPriceModel {
    return this.getStep(OfferStep.INSURANCE_TOTAL_PRICE);
  }

  storeVehicleRegistrationData(vehicleRegistrationData: VehicleRegistrationDataModel): Observable<void> {
    return this.storeStep(OfferStep.VEHICLE_REGISTRATION_DATA, vehicleRegistrationData);
  }

  getVehicleRegistrationData(): VehicleRegistrationDataModel {
    return this.getStep(OfferStep.VEHICLE_REGISTRATION_DATA);
  }

  storeVehicleOwnerAdditionalAddressByStep(step: OfferStep, vehicleOwnerAdditionalAddress: VehicleOwnerAdditionalAddressModel): Observable<void> {
    switch (step) {
      case OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS:
        return this.storeStep(OfferStep.VEHICLE_OWNER_ADDITIONAL_ADDRESS, vehicleOwnerAdditionalAddress);
      case OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS:
        return this.storeStep(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS, vehicleOwnerAdditionalAddress);
      case OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS:
        return this.storeStep(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS, vehicleOwnerAdditionalAddress);
    }

    return this.storeStep(step, vehicleOwnerAdditionalAddress);
  }

  getVehicleOwnerAdditionalAddressByStep(step: OfferStep): VehicleOwnerAdditionalAddressModel {
    switch (step) {
      case OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS:
        return this.getStep(OfferStep.VEHICLE_OWNER_ADDITIONAL_ADDRESS);
      case OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS:
        return this.getStep(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS);
      case OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS:
        return this.getStep(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS);
    }

    return this.getStep(step);
  }

  storeVehicleOwnerAdditionalAddress(vehicleOwnerAdditionalAddress: VehicleOwnerAdditionalAddressModel): Observable<void> {
    return this.storeStep(OfferStep.VEHICLE_OWNER_ADDITIONAL_ADDRESS, vehicleOwnerAdditionalAddress);
  }

  getVehicleOwnerAdditionalAddress(): VehicleOwnerAdditionalAddressModel {
    return this.getStep(OfferStep.VEHICLE_OWNER_ADDITIONAL_ADDRESS);
  }

  storeVehicleFirstCoOwnerAdditionalAddress(vehicleFirstCoOwnerAdditionalAddress: VehicleOwnerAdditionalAddressModel): Observable<void> {
    return this.storeStep(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS, vehicleFirstCoOwnerAdditionalAddress);
  }

  getVehicleFirstCoOwnerAdditionalAddress(): VehicleOwnerAdditionalAddressModel {
    return this.getStep(OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS);
  }

  storeVehicleSecondCoOwnerAdditionalAddress(vehicleSecondCoOwnerAdditionalAddress: VehicleOwnerAdditionalAddressModel): Observable<void> {
    return this.storeStep(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS, vehicleSecondCoOwnerAdditionalAddress);
  }

  getVehicleSecondCoOwnerAdditionalAddress(): VehicleOwnerAdditionalAddressModel {
    return this.getStep(OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS);
  }

  storeContactAndConsent(contactAndConsent: ContactAndConsentModel): Observable<void> {
    return this.storeStep(OfferStep.CONTACT_AND_CONSENT, contactAndConsent);
  }

  getContactAndConsent(): ContactAndConsentModel {
    return this.getStep(OfferStep.CONTACT_AND_CONSENT);
  }

  storeSavedCallbackForm(callbackForm: CallbackModel) {
    this.storeItem(CalculationStoreKey.SAVED_CALLBACK_FORM, callbackForm);
  }

  getSavedCallbackForm(): CallbackModel {
    return this.getItem(CalculationStoreKey.SAVED_CALLBACK_FORM);
  }

  getProposalData(): CepikResponse {
    return this.getItem(CalculationStoreKey.PROPOSAL_DATA);
  }

  storeProposalData(data: CepikResponse) {
    this.storeItem(CalculationStoreKey.PROPOSAL_DATA, data);
  }

  getOmniOfferData(): OfferRecalculatedResponse {
    return this.getItem(CalculationStoreKey.OMNI_OFFER_DATA);
  }

  storeOmniOfferData(data: OfferRecalculatedResponse | ModifyCalculationResponseModel) {
    this.storeItem(CalculationStoreKey.OMNI_OFFER_DATA, data);
  }

  storeInsurerData(data: PersonalDataStoreModel) {
    this.storeItem(PersonalDataType.INSURER, data);
  }

  getInsurerData(): PersonalDataStoreModel | null {
    return this.getItem(PersonalDataType.INSURER);
  }

  storeLandingPageCorrectDataCount(count: number) {
    this.storeItem(CalculationStoreKey.LANDING_PAGE_CORRECT_DATA_COUNT, count);
  }

  getLandingPageCorrectDataCount(): number {
    return this.getItem(CalculationStoreKey.LANDING_PAGE_CORRECT_DATA_COUNT);
  }

  getAllOwnersDetails(): PersonalDataStoreModel[] {
    const allOwners: PersonalDataStoreModel[] = [];
    const owner = this.getItem(PersonalDataType.OWNER);
    const firstCoOwner = this.getItem(PersonalDataType.FIRST_CO_OWNER);
    const secondCoOwner = this.getItem(PersonalDataType.SECOND_CO_OWNER);

    if (owner) {
      allOwners.push(owner);
    }

    if (firstCoOwner) {
      allOwners.push(firstCoOwner);
    }

    if (secondCoOwner) {
      allOwners.push(secondCoOwner);
    }

    return allOwners;
  }

  clearAllOwnersDetails() {
    this.clearItem(Step.VEHICLE_OWNER_DETAILS);
    this.clearItem(Step.VEHICLE_FIRST_CO_OWNER_DETAILS);
    this.clearItem(Step.VEHICLE_SECOND_CO_OWNER_DETAILS);
  }

  clearIfInsuranceTypeChange(currentInsuranceType: InsuranceType) {
    const vehicle = this.getVehicle();

    if (vehicle?.insuranceType !== currentInsuranceType) {
      this.clearItem(OfferStep.INSURANCE_VARIANT);
      this.clearItem(OfferStep.INSURANCE_ADDITIONAL_OPTION);
      this.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_RESPONSE);
      this.clearCalculationStoreKey(CalculationStoreKey.SAVED_CALCULATION_FORM);
      this.clearCalculationStoreKey(CalculationStoreKey.ADDITIONAL_COVERS);
      this.clearCalculationStoreKey(CalculationStoreKey.ADDITIONAL_INSURANCE_VARIANT);
    }
  }

  private getUrlParamsForCalculationRequest(): UrlParamsModel {
    let urlParamsModel: UrlParamsModel = new UrlParamsModel();
        urlParamsModel.orlenUID = this.urlParamsStoreService.getOrlenUID();
        urlParamsModel.sid = this.urlParamsStoreService.getInitShortPathSID();

    return urlParamsModel;
  }

  getCalculationForm(): CalculationFormModel {
    const variants = this.getCalculationResponse()?.variants || null;

    return new CalculationFormModel(
      this.getLandingPage(),
      this.getVehicle(),
      this.getVehicleAdditionalInfo(),
      this.getVehicleOwner(),
      this.mapPersonalData(PersonalDataType.OWNER),
      this.mapPersonalData(PersonalDataType.FIRST_CO_OWNER),
      this.mapPersonalData(PersonalDataType.SECOND_CO_OWNER),
      this.mapPersonalData(PersonalDataType.INSURER),
      this.getInsuranceStartDate(),
      this.getInsuranceVariant(),
      this.getInsuranceTotalPrice(),
      this.getVehicleOwnerAdditionalAddress(),
      this.getVehicleFirstCoOwnerAdditionalAddress(),
      this.getVehicleSecondCoOwnerAdditionalAddress(),
      this.getVehicleRegistrationData(),
      this.getContactAndConsent(),
      this.getUrlParamsForCalculationRequest(),
      variants,
      this.getFlowId(),
      this.getOfferNumber()
    );
  }

  private mapPersonalData(personalDataType: PersonalDataType) {
    const vehicleOwner = this.getVehicleOwner();
    const personalData = this.getItem(personalDataType);
    const insuranceStartDate = this.getInsuranceStartDate();
    const largeFamilyCardNumber = insuranceStartDate.largeFamilyCardNumber;
    const vitayCardNumber = insuranceStartDate.vitayCardNumber;

    if (vehicleOwner.mainUser === personalDataType) {
      personalData.largeFamilyCardNumber = largeFamilyCardNumber ? largeFamilyCardNumber : null;
      personalData.vitayCardNumber = vitayCardNumber ? vitayCardNumber : null;
    }

    if (personalData && personalData.lastName === personalData.previousLastName) {
      personalData.previousLastName = "";
    }

    return personalData ? personalData : null;
  }

  clearCalculationStoreKey(key: CalculationStoreKey) {
    this.clearItem(key);
  }

  clear(): void {
    Object.values(Step).forEach(key => this.clearStep(key));
    Object.values(OfferStep).forEach(key => this.clearStep(key));
    Object.values(CalculationStoreKey).forEach(key => this.clearItem(key));
  }

  afterStoreStep(step: Step): Observable<void> {
    return new Observable<void>(observer => {
      observer.next();
      observer.complete();
    });
  }
}
