export const GTM_FIELDS_NAME = {
  ['licensePlateNumber']: "numer-rejestracyjny",
  ['lp-consent']: "informacja-dane-osobowe",
  ['vehicleType']: "rodzaj-pojazdu",
  ['gasInstallation']: "instalacja-gazowa",
  ['steeringWheelOnRightSide']: "kierownica-prawa-strona",
  ['currentMileage']: "dotychczasowy-przebieg",
  ['usage']: "sposób-uzytkowania",
  ['mainUser']: "glowny-uzytkownik-pojazdu",
  ['youngOwner']: "mniej-niż-24-lata",
  ['youngestOwnerAge']: "wiek-najmlodszego-kierowcy",
  ['pesel-']: "pesel",
  ['peselNotKnow']: "nie-znam-pesel",
  ['gender']: "plec",
  ['ocInsuranceYear']: "liczba-lat-oc",
  ['ocDamagesInLastSixYears']: "liczba-szkod-oc",
  ['firstName-']: "imie",
  ['lastName-']: "nazwisko",
  ['previousLastName']: "poprzednie-nazwisko",
  ['phoneNumber-']: "numer-telefonu",
  ['email-']: "adres-email",
  ['driverLicenseObtainYear']: "rok-uzyskania-prawa-jazdy",
  ['postalCode']: "kod-pocztowy",
  ['city']: "miejscowosc",
  ['city-address']: "miejscowosc",
  ['street-address']: "ulica",
  ['houseNumber-address']: "numer-domu",
  ['flatNumber-address']: "numer-lokalu",
  ['allConsentsSelect']: "zaznaczenie-wszystkich-zgod",
  ['calculate_consent_INFO_OBLIGATION_WITH_PROFILING']: "zaznaczenie-info-dane-osobowe",
  ['calculate_consent_ANTI_GROUP']: "calculate_consent_ANTI_GROUP",
  ['calculate_consent_E_SERVICE_TERM']: "zaznaczenie-regulamin-droga-elektroniczna",
  ['calculate_consent_CONTACT_EMAIL_MRKT']: "zaznaczenie-zgody-cele-marketingowe",
  ['calculate_consent_CONTACT_PHONE']: "zaznaczenie-zgody-ofert-automatow",
  ['calculate_consent_PZU_DATA_SHARING_MRKT']: "calculate_consent_PZU_DATA_SHARING_MRKT",
  ['kdr-checkbox']: "karta-duzej-rodziny",
  ['largeFamilyCardNumber']: "karta-duzej-rodziny",
  ['promotional-code-checkbox']: "kod-promocyjny",
  ['promotionalCode']: "kod-promocyjny",
  ['installments-checkbox']: "raty",
  ['vin']: "vin",
  ['isRegisteredAddressTheSameAsHomeAddress']: "adres-zameldowania-taki-sam",
  ['isCorrespondenceAddressTheSameAsHomeAddress']: "adres-korespodencyjny-taki-sam",
  ['insurer']: "kto-zawiera-umowe",
  ['sell_consent_BUNDLE_CAR_AND_FORM']: "sell_consent_BUNDLE_CAR_AND_FORM",
  ['sell_consent_IMPORTANT_INFO']: "sell_consent_IMPORTANT_INFO",
  ['sell_consent_INSPECTION']: "sell_consent_INSPECTION",
  ['sell_consent_BUNDLE_IDD']: "sell_consent_BUNDLE_IDD",
  ['sell_consent_RENOUNCEMENT']: "sell_consent_RENOUNCEMENT",
  ['sell_consent_SELLER_INTRODUCTION']: "sell_consent_SELLER_INTRODUCTION",
  ['sell_consent_DATA_UPDATE']: "sell_consent_DATA_UPDATE",
  ['cover_3464']: "auto-assistance",
  ['cover_8086']: "szyby",
  ['cover_286998']: "pomoc-kradziez",
  ['cover_282663']: "wracaj-do-zdrowia",
  ['cover_308246']: "auto-zastepcze-na-czas-naprawy",
  ['authCode']: "wypelnienie-kod-sms",
  ['vehicleProductionYear']: "rok-produkcji",
  ['vehicleBrand']: "marka",
  ['vehicleModel']: "model",
  ['vehicleVersion']: "wersja",
  ['birthDate']: "data-urodzenia",
  ['groupInputDate']: "data-urodzenia",
  ['startDate']: "data-rozpoczecia-ubezpieczenia",
}
